import React from "react";
import logo from "./images/logo.png";
import App from "./App";
import ReactDOM from "react-dom";
import logo_Desafio from "./images/logo_desafio.svg";

function SetFormato(valor = 0){
  return new Intl.NumberFormat("en-US").format(valor)+".00"
}

function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}


export default class Appereance extends React.Component{

  constructor(props) {
    super();
    this.state = {showForm:false}
  }

  componentDidMount() {
    localStorage.removeItem("parameters");
    this.setDescription();
    this.setAmount();
    this.setSuccess();
    this.setName();
    this.setPhone();
    this.setMail();
    this.verifyState();
    this.init();
  }

  init(){
    if(this.getParameterByName("correo")){
      localStorage.setItem("parameters",window.location.href.split("?")[1])

      var datas = localStorage.getItem("parameters");
      datas = datas.replaceAll(" ","%20");
      datas = datas.replaceAll(" ","%20")
      datas = datas.replaceAll("ñ","n")
      datas = datas.replaceAll("Ñ","N")
      datas = datas.replaceAll("á","a")
      datas = datas.replaceAll("é","e")
      datas = datas.replaceAll("í","i")
      datas = datas.replaceAll("ó","o")
      datas = datas.replaceAll("ú","u")
      datas = datas.replaceAll("Á","A")
      datas = datas.replaceAll("É","E")
      datas = datas.replaceAll("Í","I")
      datas = datas.replaceAll("Ó","O")
      datas = datas.replaceAll("Ú","U")

      console.log(datas)
    }else{
      document.querySelector("#correo").addEventListener('keyup',evt => {
          localStorage.setItem(
            "parameters",
            window.location.href.split("?")[1]+
            "&nombre="+document.getElementById("nombre").value+
            "&phone="+document.getElementById("telefono").value+
            "&correo="+document.getElementById("correo").value
          )

        localStorage.setItem(
          "ac_parameters",
          window.location.href.split("?")[1]+
          "&nombre="+document.getElementById("nombre").value+
          "&phone="+document.getElementById("telefono").value+
          "&correo="+document.getElementById("correo").value
        )

        var datas = localStorage.getItem("parameters");
        datas = datas.replaceAll(" ","%20");
        datas = datas.replaceAll(" ","%20")
        datas = datas.replaceAll("ñ","n")
        datas = datas.replaceAll("Ñ","N")
        datas = datas.replaceAll("á","a")
        datas = datas.replaceAll("é","e")
        datas = datas.replaceAll("í","i")
        datas = datas.replaceAll("ó","o")
        datas = datas.replaceAll("ú","u")
        datas = datas.replaceAll("Á","A")
        datas = datas.replaceAll("É","E")
        datas = datas.replaceAll("Í","I")
        datas = datas.replaceAll("Ó","O")
        datas = datas.replaceAll("Ú","U")


        localStorage.setItem("parameters",datas)
        console.log(datas)
      })
    }
  }

  setMail(){
    if(this.getParameterByName("correo")){
        document.getElementById("correo").value = this.getParameterByName("correo")
      document.getElementById("correo").classList.add("is-valid")
      document.getElementById("correo").classList.remove("is-invalid")
    }
  }

  setPhone(){
    if(this.getParameterByName("phone")){
      document.getElementById("telefono").value = this.getParameterByName("phone");
      document.getElementById("telefono").classList.remove("is-invalid")
      document.getElementById("telefono").classList.add("is-valid")
    }
  }

  setName(){
    if (this.getParameterByName("nombre") && this.getParameterByName("apellidos")){
      document.getElementById("nombre").value = this.getParameterByName("nombre") +" "+
          this.getParameterByName("apellidos")

      document.getElementById("nombre").classList.remove("is-invalid")
      document.getElementById("nombre").classList.add("is-valid")
    }
  }

  setDescription(){
    //colocamos la descripcion que obtivimos del get desc
    document.getElementById("description").textContent = this.getParameterByName("desc")
  }

  setAmount(){
    //colocamos la cantidad de dinero que cuesta un boleto de acierto al get amount
    document.querySelector("h1 .cantidad").textContent = SetFormato(this.getParameterByName("amount")) + " MXN"
    document.querySelector("h1 .cantidad").setAttribute("value",this.getParameterByName("amount")+".00")
  }

  setSuccess(){
    document.querySelector(".success").textContent = this.getParameterByName("success")
  }

  handleChange(evt){
    var cantidad = Number(this.getParameterByName("amount"))
    var boletos = evt.target.value;
    var total = cantidad * boletos


    document.querySelector(".cantidad").textContent = SetFormato(total)+" MXN"
    document.querySelector(".cantidad").setAttribute("value",total)

    this.setRetribution()

    document.querySelector(".checbox-retribucion").checked = false


  }

/*___________________________________*/




  getParameterByName(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  handleClick(evt){
    var bandera = true;

    document.querySelectorAll(".datas-container input,.datas-container select").forEach(element=>{
      if (element.classList.contains("is-invalid")){
        bandera = false;
      }
    })

    if (!bandera){
      alert("Algunos elementos no han sido llenados correctamente, por favor verifica que todos los campos son de color verde e intentalo de nuevo.")
    }else{
      var cantidad = document.querySelector(".cantidad").textContent;
      cantidad = cantidad.replaceAll(" MXN","")
      cantidad = cantidad.replaceAll(",","")
      cantidad = cantidad.replaceAll("$","")
      cantidad = cantidad.replace(".00","")

      let formData = new FormData()

      formData.append("firstname", document.getElementById("nombre").value);
      formData.append("email",document.getElementById("correo").value);
      formData.append("phone",document.getElementById("telefono").value)
      formData.append("61",cantidad);
      formData.append("111",cantidad);

      let parameters = new Appereance();

      if(parameters.getParameterByName("utm_campaign") != null){
        formData.append("71",parameters.getParameterByName("utm_campaign"));
      }
      if(parameters.getParameterByName("tipo_de_donante") !== null){
        formData.append("41",parameters.getParameterByName("tipo_de_donante"));
      }
      if(parameters.getParameterByName("utm_source") != null){
        formData.append("72",parameters.getParameterByName("utm_source"));
      }
      if(parameters.getParameterByName("utm_content") != null){
        formData.append("74",parameters.getParameterByName("utm_content"));
      }
      if(parameters.getParameterByName("utm_term") != null){
        formData.append("101",parameters.getParameterByName("utm_term"));
      }
      if(parameters.getParameterByName("utm_medium") != null){
        formData.append("101",parameters.getParameterByName("utm_medium"));
      }


      fetch("https://api.savethechildrenmx.org/save_contact.php",{
        method: 'POST',
        body: formData
      }).then(resp=>{
        if(resp.status === 200){
          ReactDOM.render(<App/>, document.querySelector(".app-container"));
          document.querySelector(".app-container").style.display = "flex"
          document.querySelector(".datas-container").style.display = "none"
          document.querySelector(".datas-container").nextElementSibling.style.display = "none"
        }else{
          console.error(resp.statusText)
        }
      })
    }
  }

  verifyState(){
    var flag = true;


    document.querySelectorAll(".datas-container .form-group input").forEach(element=>{
      if(element.classList.contains("is-invalid")){
        flag = false;
      }
    })

    if (flag){
      document.querySelector(".button-continuar").removeAttribute("disabled")
    }else{
      document.querySelector(".button-continuar").setAttribute("disabled","disabled")
      document.querySelector(".button-continuar").addEventListener("click",this.handleClick)
    }
  }

  handleNombre(evt){
    var regex = new RegExp("^[A-Za-záéíóúÁÉÍÓÚÑñ]+( [A-Za-záéíóúÁÉÍÓÚÑñ]+){2,}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    this.verifyState();
  }

  handleCorreo(evt){
    var regex = new RegExp("^^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    this.verifyState()
  }

  handleTelefono(evt){
    var regex = new RegExp("^[0-9]{10}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    var letras = new RegExp("[A-ZÑÁÉÍÓÚa-zñáéíóú[^$.|\\-_?*+(){}]");

    if(letras.exec(evt.target.value) != null){
      evt.target.value = evt.target.value.substr(0,evt.target.value.length-1)
    }

    this.verifyState()
  }

  render() {
    return (
      <div className="checkout-container">
        <div className="information-container">
          <div className="images-container">
            <img src={logo} alt="stc-logo"/>
          </div>
          <div className="label-container">

            <h3>Hacer donativo a:</h3>
            <h2>
              FUNDACION MEXICANA DE APOYO INFANTIL AC
            </h2>
            <h3>Para</h3>
            <h2 id="description">
            </h2>
            <h3>Por la cantidad de</h3>
            <div className="cantidad_container">
              <h1><div className="simbol">$</div><div className="cantidad">200</div></h1>
            </div>
            <div className="success"></div>
            <div className="prefijo"></div>
            <div className="datas-container">
              <div className="form-group">
                <label htmlFor="">Por favor escribe tu nombre y 2 apellidos:</label>
                <input type="text" id="nombre" onKeyUp={this.handleNombre.bind(this)} className="is-invalid"/>
              </div>
              <div className="form-group">
                <label htmlFor="">Número telefónico a 10 dígitos:</label>
                <input type="text" id="telefono" onKeyUp={this.handleTelefono.bind(this)} className="is-invalid" inputMode="numeric"/>
              </div>
              <div className="form-group">
                <label htmlFor="">Correo electrónico:</label>
                <input type="email" id="correo" onKeyUp={this.handleCorreo.bind(this)} className="is-invalid"/>
              </div>

            </div>


            <button onClick={this.handleClick} className="button-continuar" disabled>Continuar</button>
          </div>
        </div>

        <div className="app-container">

        </div>
      </div>
    );
  }
}